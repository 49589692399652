<template lang="pug">
ui-table(
  :data="data"
  :columns="columns"
  :loader="loader"
  :has-error="hasError"
  :has-filters="hasFilters"
  :header-sticky="true"
  v-model="preFiltrationForm"
  @apply="$emit('applyFilters')"
)
  template(#dateCreate="{ rowData }") {{ formatDateTime(rowData.dateCreate) }}
  template(#consultingService="{ rowData }")
    span {{ rowData.consultingService.title }}
    span.secondary(v-if="rowData.systemComment") {{ rowData.systemComment }}
    lot-link(v-if="isTmManager && rowData.lot" :id="rowData.lot.id" :number="rowData.lot.govRuId")
  template(#lot="{ rowData }")
    lot-link(v-if="rowData.lot" :id="rowData.lot.id" :number="rowData.lot.govRuId")
  template(#customer="{ rowData }")
    span Клиент: {{ rowData.name }}
    span {{ rowData.company ? `Компания: ${ rowData.company?.title }${ rowData.company?.inn ? ', ИНН: ' +  rowData.company?.inn : '' }` : 'Компания заказчика неизвестна' }}
    span(v-if="rowData.phoneNumber") {{ rowData.phoneNumber }}
    span.secondary(v-if="rowData.email") {{ rowData.email }}
    span.secondary(v-if="rowData.timezone") {{ getTimezoneString(rowData.timezone) }}
    span.secondary(v-if="rowData.comment" style="white-space: pre-wrap;") {{ rowData.comment }}
  template(#cost="{ rowData }") {{ (!rowData.cost && rowData.cost !== 0) ? 'Ожидает расчета' : costFormatterRu().format(rowData.cost) }}
  template(#status="{ rowData }")
    ui-tag.initial(
      v-if="rowData.orderExecution?.status || !isTmManager"
      :name="getStatusLabel(rowData.orderExecution?.status)"
      :type="getStatusType(rowData.orderExecution?.status?.id)"
    )
  template(#icon="{ rowData }")
    ui-icon.edit-icon(clickable :icon="UiIconNames.Icon_Edit" @click="onEditClick(rowData)")

edit-order-dialog(
  v-if="isTmManager && showEditOrderDialog"
  v-model:show="showEditOrderDialog"
  v-model:order="selectedOrder"
  @history:show="showHistory"
)
order-execution-history(
  v-if="isTmManager && showOrderHistoryDialog"
  v-model:show="showOrderHistoryDialog"
  :order="selectedOrder"
)
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent, ref, } from 'vue'
import { formatDateTime, getTimezoneString } from "@/utils/formatter/dateFormatter";
import { useVModel } from "@vueuse/core";
import { getStatusType } from "@/utils/superAccount/superAccount";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { costFormatterRu } from "@/utils/formatter/costFormatter";
import { getDefaultPreFiltrationFormService } from "@/utils/getters/defaultFilters";
import type { PropType } from "vue";
import type { OrderedTenderI } from "@/use/tenderService/TenderServiceInterface";
import type { PreFiltrationFormServiceI } from "@/utils/getters/defaultFilters";
import type { BaseObjectI } from "@/stores/manuals/ManualsInterface";
import UiTag from "@/components/ui/tag/UiTag.vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiTable from "@/components/ui/table/UiTable.vue";
import LotLink from "@/components/ui/links/LotLink.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

import useManualsStore from "@/stores/manuals/useManualsStore";
import LotNumberFilter from "@/components/filters/services/LotNumberFilter.vue";
import ServiceStatusFilter from "@/components/filters/services/ServiceStatusFilter.vue";
import ServiceNameFilter from "@/components/filters/services/ServiceNameFilter.vue";
import ServiceInfoFilter from "@/components/filters/services/ServiceInfoFilter.vue";
import ClientFilter from "@/components/filters/services/ClientFilter.vue";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "OrderHistoryTable",
  methods: {
    formatDateTime,
    getStatusType,
    costFormatterRu,
    getTimezoneString,
  },
  components: {
    LotLink,
    UiIcon,
    UiTag,
    UiTable,
    OrderExecutionHistory: defineAsyncComponent(() => import("@/components/pages/services/dialogs/OrderExecutionHistory.vue")),
    EditOrderDialog: defineAsyncComponent(() => import("@/components/pages/services/dialogs/EditOrderDialog.vue")),
  },
  emits: [
    'update:filters',
    'applyFilters',
  ],
  props: {
    data: {
      type: Array as PropType<OrderedTenderI[]>,
      default: () => [],
    },
    loader: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object as PropType<PreFiltrationFormServiceI>,
      default: getDefaultPreFiltrationFormService(),
    },
    hasFilters: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {

    const { isTmManager } = useUserAccess();
    const preFiltrationForm = useVModel(props, 'filters', emit);

    const showEditOrderDialog = ref(false);
    const showOrderHistoryDialog = ref(false);
    const selectedOrder = ref<OrderedTenderI|null>(null);

    const manualsStore = useManualsStore()
    const tenmonManagerStore = useTmManagerStore();
    const { selectedCompanyInfo } = storeToRefs(tenmonManagerStore);

    function onEditClick(order: OrderedTenderI) {
      selectedOrder.value = order;
      showEditOrderDialog.value = true;
    }

    function showHistory() {
      showOrderHistoryDialog.value = true;
    }

    function getStatusLabel(status: BaseObjectI) {
      if (status && isTmManager.value) {
        return status.title;
      } else if (status && !isTmManager.value) {
        return [5, 6].includes(status?.id) ? 'Отменено' : status?.title;
      } else {
        return !isTmManager.value ? 'В обработке' : '';
      }
    }

    function getServiceStatusValue() {
      return preFiltrationForm.value.serviceStatusIds.map(s => {
        if (s === -1) return 'Отменено';
        return manualsStore.serviceStatuses.find((e: BaseObjectI) => s === e.id)?.title || null;
      }).filter(e => e).join(', ')
    }

    function getConsultingServiceValue() {
      return [
          manualsStore.tenderServicesGetter.find((e: BaseObjectI) => preFiltrationForm.value.serviceNameId === e.id)?.title || null,
          isTmManager.value ? preFiltrationForm.value.govRuId : null,
        ].filter(a => a).join(', ')
    }

    function getClientLabel() {
      return preFiltrationForm.value.customerCompany?.title || ''
    }

    const columns = computed(() => ({
      dateCreate: { size: 136, bold: true, isSort: true, headerLabel: 'Дата' },
      consultingService: { size: !isTmManager.value ? 444 : 248, headerLabel: 'Наименование услуги', filterComponent: !isTmManager.value ? ServiceNameFilter : ServiceInfoFilter, value: getConsultingServiceValue() },
      lot: { size: 202, invisible: isTmManager.value, headerLabel: 'Номер закупки', filterComponent: LotNumberFilter, value: preFiltrationForm.value.govRuId },
      customer: { size: 306, invisible: !isTmManager.value, headerLabel: 'Заказчик', filterComponent: selectedCompanyInfo.value ? undefined : ClientFilter, value: getClientLabel() },
      cost: { size: 154, isSort: true, headerLabel: 'Цена' },
      status: { size: isTmManager.value ? 230 : 170, headerLabel: 'Статус', filterComponent: ServiceStatusFilter, value: getServiceStatusValue() },
      icon: { size: 32, invisible: !isTmManager.value },
    }))

    return {
      columns,
      isTmManager,
      preFiltrationForm,
      showEditOrderDialog,
      showOrderHistoryDialog,
      selectedOrder,
      UiIconNames,
      onEditClick,
      showHistory,
      getStatusLabel,
    }
  }
})
</script>

<style scoped lang="scss">
.initial {
  display: initial;
}

.secondary {
  color: var(--secondary-text-color);
  font-size: 13px;
}

.edit-icon {
  color: var(--main-color-blue);
}
</style>
