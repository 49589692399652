<template lang="pug">
filter-template.service-filter(
  @reset="cleanFilter"
  @save="applyFilters"
  @cancel="cancel"
)
  template(#header) Номер закупки
  template(#content)
    lot-number-input(v-model="filters.govRuId")
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { PreFiltrationFormServiceI } from "@/utils/getters/defaultFilters";
import { defineComponent, toRefs } from 'vue'
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import { getDefaultPreFiltrationFormService } from "@/utils/getters/defaultFilters";
import LotNumberInput from "@/components/ui/form/input/NumberAsTextInput.vue";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";

export default defineComponent({
  name: "LotNumberFilter",
  components: {
    FilterTemplate,
    LotNumberInput,
  },
  emits: [
    'close',
    'apply',
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormServiceI>,
      default: getDefaultPreFiltrationFormService(),
    },
  },
  setup(props, { emit }) {

    const { modelValue } = toRefs(props)

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormServiceI>(modelValue, false, ['govRuId'], emit, 'services')

    return {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    }
  }
})
</script>

<style scoped lang="scss">
.service-filter {
  width: 320px;
}
</style>
