<template lang="pug">
filter-template.status-filter(
  @reset="cleanFilter"
  @save="applyFilters"
  @cancel="cancel"
)
  template(#header) Статус услуги
  template(#content)
    checkbox-group(v-model="filters.serviceStatusIds" :options="statuses" id="serviceStatus")
</template>

<script lang="ts">
import type { PropType } from "vue";
import type { PreFiltrationFormServiceI } from "@/utils/getters/defaultFilters";

import { computed, defineComponent, toRefs } from 'vue'
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import { getDefaultPreFiltrationFormService } from "@/utils/getters/defaultFilters";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import useManualsStore from "@/stores/manuals/useManualsStore";

import CheckboxGroup from "@/components/ui/checkbox/CheckboxGroup.vue";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";

export default defineComponent({
  name: "ServiceStatusFilter",
  components: {
    CheckboxGroup,
    FilterTemplate,
  },
  emits: [
    'close',
    'update:modelValue',
    'apply',
  ],
  props: {
    modelValue: {
      type: Object as PropType<PreFiltrationFormServiceI>,
      default: getDefaultPreFiltrationFormService(),
    },
  },
  setup(props, { emit }) {

    const { modelValue } = toRefs(props)
    const manualsStore = useManualsStore()

    const { isTmManager } = useUserAccess();

    const statuses = computed(() =>
      isTmManager.value
        ? manualsStore.serviceStatuses
        : [...manualsStore.serviceStatuses.filter(e => ![5, 6].includes(e.id)), { id: -1, title: 'Отменено' }]
    )

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent<PreFiltrationFormServiceI>(modelValue, false, ['serviceStatusIds'], emit, 'services')

    return {
      statuses,
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    }
  }
})
</script>

<style scoped lang="scss">
.status-filter {
  width: 320px;
}
</style>
